@import '~antd/dist/antd.css';

.Appclanme {
  position: relative;

  .body-box {
    padding-top: 4.375rem;
  }
}

.legal-box {
  .title {
    height: 10.75rem;
    text-align: center;
    font-size: 2.25rem;
    font-weight: 500;
    color: #333333;
    background-image: url('/static/images/legal_bg.png');
    background-size: 100% 10.75rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .pricing-page {
      .pricing-page-info {
        font-size: 1.25rem;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .terms-info-box {
    background: #FAFAFC;
    padding: 2.625rem 22.5rem;

    .terms-info-title {
      display: block;
      font-size: 1.375rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 1rem;
    }

    .terms-info-info {
      font-size: 1.125rem;
      font-weight: 400;
      color: #333333;
      line-height: 2.1875rem;
      margin-bottom: 1rem;
    }
  }
}